<template>
    <div class="member flex-col flex-j-start flex-a-center">
        <a-form-model ref="ruleForm" :model="params" layout="inline" :style="{textAlign: 'left'}">
            
            <a-form-model-item label="性别要求" prop="Sex" :style="{width: '3.6rem'}" :labelCol="{span: 12}"
                               :wrapperCol="{span:
            12}">
               
                <a-select v-model="params.Sex" placeholder="">
                    <a-select-option key="0" :value="0">不限</a-select-option>
                    <a-select-option key="1" :value="1">男</a-select-option>
                    <a-select-option key="2" :value="2">女</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item has-feedback label="年龄要求" prop="Age" :style="{width: '4.0rem'}" :labelCol="{span: 10}"
                    :wrapperCol="{span: 14}">
                <a-select v-model="params.Age" placeholder="">
                    <a-select-option v-for="(item, index) in ageList" :key="index" :value="item">{{
                            item
                                                                                                 }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="学历要求" prop="Education" :style="{width: '4.0rem'}" :labelCol="{span: 10}"
                    :wrapperCol="{span: 14}">
                <a-select v-model="params.Education" placeholder="">
                    <a-select-option v-for="(item, index) in educationList" :key="index" :value="item">{{ item }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item has-feedback label="岗位要求" prop="PostName" :style="{width: '4.0rem'}"
                               :labelCol="{span: 9}" :wrapperCol="{span: 15}">
                <a-cascader
                    v-model="params.PostName"
                    :field-names="postNameField"
                    :options="postNameList"
                    :display-render="postNameDisplayRender"
                    expand-trigger="hover"
                    placeholder=""
                    @change="onPostNameChange"
                />
            </a-form-model-item>
            

            <a-form-model-item label="居住地要求" prop="AreaName" :style="{width: '5.4rem'}" :labelCol="{span: 8}"
                               :wrapperCol="{span: 14}">
                <a-select v-model="params.AreaName" placeholder="">
                    <a-select-option v-for="(item, index) in areaNameList" :key="index" :value="item.AreaName">{{
                            item.AreaName
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item has-feedback label=" " :colon="false" :style="{width: '2.0rem'}" :labelCol="{span: 0}"
                               :wrapperCol="{span: 14}">
                <a-button size="default" @click="onSubmit">开始匹配</a-button>
            </a-form-model-item>
        </a-form-model>
        <br>
        <div class="list" style="width: calc(16rem - 0.4rem);padding: 0 0.1rem">
            <hire-cell v-for="(item, index) in list" :key="index" :item="item"></hire-cell>
        </div>
        <empty-page v-if="!list||list.length===0" msg="输入岗位进行人员匹配"/>
    </div>
</template>

<script>
// member
// import RefreshList from "@/components/refresh-list";
import HireCell from "@/components/hire-cell";
import EmptyPage from "@/components/empty-page";

export default {
    name: "member",
    components: {
        EmptyPage, HireCell,
        // RefreshList
    },
    data() {
        return {
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
            },
            params: {
                Age: "",
                AreaName: "",
                Education: "",
                PostName: [],
                Sex: 0,
            },
            postNameField: {label: 'name', value: 'name', children: 'professionList'},
            ageList: [
                '18~25',
                '26~30',
                '31~35',
                '36~40',
                '41~45',
                '46~50',
                '>50',
            ],
            postNameList: [],
            educationList: [],
            areaNameList: [],
            list: [],
        }
    },
    mounted() {
        this.educationList = $app.getEducationOptions();
        this.areaNameList = $app.getStorageSync('areaList');
        this.postNameList = $app.getStorageSync('businessList');
    },
    methods: {
        getParams() {
            let age = this.params.Age;
            let ageMin = 18;
            let ageMax = 65;
            if (age.indexOf(">") > -1) {
                ageMin = 50;
                ageMax = 65;
            } else {
                ageMin = age.split("~")[0] || 18;
                ageMax = age.split("~")[1] || 65;
            }
            return {
                areaName: this.params.AreaName || '',
                sex: this.params.Sex || 0,
                education: this.params.Education || '',
                ageMin: ageMin,
                ageMax: ageMax,
                postName: this.params.PostName || [],
            }
        },
        async onSubmit() {
            const params = this.getParams();
            params.postName = params.postName[1];
            const res = await $app.httpGet({
                path: `/Member/PersonMatching`,
                params: params,
            });
            // this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];
                
                newList = newList.map(item => {
                    item.timeText = $app.dateFormatChinese(item.BaseCreateTime);
                    item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                    item.previteName = item.MemberName.substr(0, 1) + "**";
                    if (item.Phone) {
                        item.Phone += "";
                        try {
                            item.previtePhone = item.Phone.substr(0, 3) + "****" + item.Phone.substr(7, 11)
                        } catch (e) {
                            item.previtePhone = "***********"
                        }
                    }
                    return item;
                });
                
                // if (this.pageIndex === 1) {
                list = newList;
                // } else {
                //     list = list.concat(newList);
                //     if (newList.length < this.pageSize) {
                //         // this.$refs['refreshList'].finish();
                //     }
                // }
                this.list = list;
            }
        },
        postNameDisplayRender({labels}) {
            return labels[labels.length - 1];
        },
        onPostNameChange(value) {
            console.log(value);
            this.params.BusinessName = value[0] || ""
        },
    }
}
</script>

<style scoped lang="less">
.member {
    width: 28rem;
    padding: 0 0.1rem
}
</style>
