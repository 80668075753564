<template>
    <div class="member flex-col flex-j-start flex-a-center">
        <a-form-model ref="ruleForm" :model="params" layout="inline" :style="{textAlign: 'left'}">
    
            <a-form-model-item label="我期望的工作所在地" prop="AreaName" :style="{width: '5.4rem'}" :labelCol="{span: 14}"
                               :wrapperCol="{span: 10}">
                <a-select v-model="params.AreaName" placeholder="">
                    <a-select-option v-for="(item, index) in areaNameList" :key="index" :value="item.AreaName">{{
                            item.AreaName
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
    
            <a-form-model-item has-feedback label="我期望的薪资" prop="Salary" :style="{width: '5.0rem'}" :labelCol="{span: 12}"
                               :wrapperCol="{span: 12}">
                <a-select v-model="params.Salary" placeholder="">
                    <a-select-option v-for="(item, index) in salaryList" :key="index" :value="item">{{
                            item
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item has-feedback label="我期望的岗位" prop="PostName" :style="{width: '5.0rem'}"
                               :labelCol="{span: 12}" :wrapperCol="{span: 12}">
                <a-cascader
                    v-model="params.PostName"
                    :field-names="postNameField"
                    :options="postNameList"
                    :display-render="postNameDisplayRender"
                    expand-trigger="hover"
                    placeholder=""
                    @change="onPostNameChange"
                />
            </a-form-model-item>
            <a-form-model-item has-feedback label=" " :colon="false" :style="{width: '4.0rem'}" :labelCol="{span: 8}"
                               :wrapperCol="{span: 16}">
                <a-button size="default" @click="onSubmit">开始匹配</a-button>
            </a-form-model-item>
        </a-form-model>
        <br>
        <div class="list" style="width: calc(16rem - 0.4rem);padding: 0 0.1rem">
            <office-list-cell v-for="(item, index) in list" :key="index" :item="item"></office-list-cell>
        </div>
        <empty-page v-if="!list||list.length===0" msg="输入条件进行岗位匹配"/>
    </div>
</template>

<script>
// member
// import RefreshList from "@/components/refresh-list";
// import HireCell from "@/components/hire-cell";
import EmptyPage from "@/components/empty-page";
import OfficeListCell from "@/components/office-list-cell";

export default {
    name: "office",
    components: {
        OfficeListCell,
        EmptyPage
        // , HireCell,
        // RefreshList
    },
    data() {
        return {
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
            },
            params: {
                Age: "",
                AreaName: "",
                Salary: "",
            },
            postNameField: {label: 'name', value: 'name', children: 'professionList'},
            salaryList: [
                '不限',
                '2000~5000',
                '5000~10000',
                '10000~15000',
                '15000~20000',
                '>20000',
            ],
            postNameList: [],
            educationList: [],
            areaNameList: [],
            list: [],
        }
    },
    mounted() {
        this.educationList = $app.getEducationOptions();
        this.areaNameList = $app.getStorageSync('areaList');
        this.postNameList = $app.getStorageSync('businessList');
    },
    methods: {
        getParams() {
            let salary = this.params.Salary;
            let salaryMin = 0;
            let salaryMax = 0;
            if (salary.indexOf(">") > -1) {
                salaryMax = 0;
                salaryMin = 20000;
            } else {
                salaryMin = salary.split("~")[0] || 0;
                salaryMax = salary.split("~")[1] || 0;
            }
            return {
                areaName: this.params.AreaName || '',
                salaryMin: salaryMin,
                salaryMax: salaryMax,
                postName: this.params.PostName || [],
            }
        },
        async onSubmit() {
            const params = this.getParams();
            params.postName = params.postName[1];
            const res = await $app.httpGet({
                path: `/BasePostInfo/PostMatching`,
                params: params,
            });
            // this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];
                
                newList = newList.map(item => {
                    let Requirements = item.Requirements || "";
                    let Welfare = item.Welfare || "";
                    // 汉字逗号改为英文逗号
                    Requirements = Requirements.replace(/，/g, ',').replace(/、/g, ",");
                    item.RequirementsList = Requirements.split(",");
    
                    Welfare = Welfare.replace(/，/g, ",").replace(/、/g, ",");
                    item.WelfareList = Welfare.split(",");
    
                    if (item.RequirementsList[0] == "") {
                        item.RequirementsList = [];
                    }
                    if (item.WelfareList[0] == "") {
                        item.WelfareList = [];
                    }
    
                    item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                    return item;
                });
                
                // if (this.pageIndex === 1) {
                list = newList;
                // } else {
                //     list = list.concat(newList);
                //     if (newList.length < this.pageSize) {
                //         // this.$refs['refreshList'].finish();
                //     }
                // }
                this.list = list;
            }
        },
        postNameDisplayRender({labels}) {
            return labels[labels.length - 1];
        },
        onPostNameChange(value) {
            console.log(value);
            this.params.BusinessName = value[0] || ""
        },
    }
}
</script>

<style scoped lang="less">
.member {
    width: 28rem;
    padding: 0 0.1rem
}
</style>
