<template>
    <div class="user flex-row flex-j-between flex-a-center tap" @click="onClick">
        <img :src="item.HeadImage" class="icon"/>
        <div class="flex-col flex-j-around flex-a-start flex-1">
            <div>
                <span>{{ item.previteName }}</span>
                <!-- <span v-if="{{item.Education}}" class="edu">{{item.Education}}</span> -->
            </div>
            <div style="font-size: 0.32rem">
                <span v-if="item.Sex==1" style="color: #4eabe8;">男</span>
                <span v-if="item.Sex==2" style="color: #f1676d;">女</span>
                <span v-if="item.Age>0" style="padding-left: .20rem;">{{ item.Age }}岁</span>
                <span v-if="item.Education">,{{ item.Education }}</span>
            </div>
            <div style="font-size: 0.32rem">
                <span>期望岗位：{{ item.PostName }}</span>
            </div>
        </div>
        <span style="color: #999999;font-size: 0.32rem">{{ item.previtePhone }}</span>
    </div>
</template>

<script>
// hire-cell
// import LazyImg from "@/components/lazy-img";
export default {
    name: "hire-cell",
    components: {},
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {
        onClick() {
            this.$router.push(`/hire-detail?id=${this.item.Id}`)
        }
    }
}
</script>

<style scoped lang="less">
.user {
    padding: .30rem;
    background-color: #fff;
    border-radius: .12rem;
    margin-bottom: .20rem;
    font-size: .4rem;
    color: #666666;
    transition: all 0.3s ease;
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    }
}

.user .icon {
    width: 1.60rem;
    height: 1.60rem;
    margin-right: .20rem;
    border-radius: .12rem;
}

.user .edu {
    background-color: #4eabe8;
    color: #fff;
    font-size: .28rem;
    padding: .04rem .12rem;
    border-radius: .06rem;
    margin-left: .10rem;
}
</style>
